<template>
  <div class="py-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
    <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">Profile</h1>
  </div>
  <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
    {{currentUser.user_metadata.full_name}}<br />
    {{currentUser.email}}<br />

    <router-link to="/subscription" type="button" class="inline-flex items-center mt-4 px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      Manage subscription
    </router-link>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "Profile",
    data() {
      return {
      };
    },
    computed: {
      ...mapGetters("auth", ["currentUser"]),
    },
  };
</script>
